import React, {createElement, Fragment, useEffect, useMemo, useRef, useState} from 'react';
import styles from "./styling/diary.module.css";
import {useDiary} from "./DiaryProvider";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {Box, Button, CircularProgress, SvgIcon, TextField,} from "@mui/material";
import styled, {useTheme} from "styled-components";

import SendIcon from '@mui/icons-material/Send';
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/LockOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';

import classNames from "classnames";
import {useWeb3React} from "@web3-react/core";
import {Contract} from "ethers";
import parse from "html-react-parser";
import {DiaryEntrySection, EditorStyling, OptionPanel, ReleaseDiv, UnlockModal} from "./styling/Diary.styles";
import Logo from "../common/logo/Logo";
import {Flip, toast} from "react-toastify";
import QRCode from "react-qr-code";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PropTypes from "prop-types";
import Diary from "./Diary.string";
import ABI from "../abi";
import {ExitDiv} from "../published/PublishedDiaries.styles";
import Seal from "../common/Seal";


function MyDiary(props) {

    const theme = useTheme();

    const {activate, deactivate, active, chainId, account, library} = useWeb3React();

    const {diary, unlocked} = useDiary();
    // console.log(props)
    let diaryContract = new Contract(props.address, props.abi, library.getSigner());

    const diaryEntriesRef = useRef(null);

    const [text, setText] = useState("");
    const [editMode, setEditMode] = useState(null);
    const [decryptedDiary, setDecryptedDiary] = useState([])

    // const [unlock, setUnlock] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [sendIcon, setSendIcon] = useState(
	<>
	    <SendIcon/>
	    <label style={{fontSize: "0.8em", pointerEvents: "none"}}>Save Encrypted</label>
	</>
    );

    const [isSealed, setIsSealed] = useState(false);

    const handleOpenBook = () => {
	if (diary.isUnlocked() === false) {
	    if(!showModal){
		setShowModal(true)
	    }
	}
    }
    const handleCloseBook = () => {
	setDecryptedDiary([])
	diary.lockDiary()
    }

    const decryptMsg = async (msg) => {
	let message = await diary.decryptMessage(msg);
	return message;
    }
    /**
     * Fetches the Entries of the Diary (related to pub-Key of Diary)
     */
    const fetchDiary = () => {

	(async () => {
	    // console.log(diaryContract, diary.owner.address)
	    let diaryEntries = await diaryContract.readDiary(diary.owner.address);
	    console.log("Encrypted Diary: ", diaryEntries)

	    let history = [];

	    for (let msg of diaryEntries) {
		let decryptedMsg = await decryptMsg(msg.entry)
		history.push([decryptedMsg, msg.timestamp.toNumber()]);
	    }

	    console.log("Decrypted Diary: ", history)
	    setDecryptedDiary(history)
	})();
    }

    /**
     * Creating Transaction for sending Text-Msg to Blockchain
     * @param text
     * @param encrypted
     */
    const sendMsg = (text, encrypted = true, edit = false) => {

	if (encrypted) {
	    console.log("Write Encrypted Message", text)
	    diary.encryptMessage(text).then((encryptedMessage) => {

		if (edit === true) {
		    console.log("EditEntry", text, editMode.index, encryptedMessage)
		    diaryContract.editDiaryEntry(diary.owner.address, editMode.index, encryptedMessage).then((tx) => {

			setSendIcon(
			    <>
				<CircularProgress color="inherit"/>
				<label style={{fontSize: "0.8em"}}>Confirming</label>
			    </>
			)
			tx.wait().then(result => {
			    console.log("MSG SEND TO BLOCKCHAIN", result)
			    fetchDiary()
			    setText("")
			    setSendIcon(
				<>
				    <SendIcon/>
				    <label style={{fontSize: "0.8em"}}>Send Encrypted</label>
				</>
			    )
			})
		    }).catch((error) => {

		    })
		}
		else {
		    //Here goes the sending of the encrypted Chat-Message
		    diaryContract.writeToDiary(diary.owner.address, encryptedMessage).then((tx) => {

			setSendIcon(
			    <>
				<CircularProgress color="inherit"/>
				<label style={{fontSize: "0.8em"}}>Confirming</label>
			    </>
			)

			tx.wait().then(result => {
			    console.log("MSG SEND TO BLOCKCHAIN", result)
			    fetchDiary()
			    setText("")
			    setSendIcon(
				<>
				    <SendIcon/>
				    <label style={{fontSize: "0.8em"}}>Send Encrypted</label>
				</>
			    )
			})
		    }).catch((error) => {
			console.error(error)
			toast.info(error.reason)
		    })
		    // console.log("Send to Blockchain", text)
		    // console.log(encryptedMessage)
		    // setEncryptedDiary(encryptedMessage)
		}

	    }).catch((error) => {
		console.error(error)
	    })
	} else {
	    console.log("Write to Chat unencrypted: ", text)
	    diaryContract.writeToDiary(diary.owner.address, text).then((tx) => {
		tx.wait().then(result => {
		    console.log("MSG SEND TO BLOCKCHAIN", result)
		    setText("")
		})
	    }).catch((error) => {
		console.error(error)
	    })
	}
    }

    const entryHover = (elem) => {

	if(!isSealed){

	    // console.log(elem.currentTarget.children)

	    let elements = Array.from(elem.currentTarget.children).filter(child => child.id !== "edit");
	    // for (let element of elements) {
	    //     console.log(element)
	    //     // element.style.paddingRight = "100px";
	    // }
	    elem.currentTarget.children.edit.style.display = "flex"
	}

    }
    const entryHoverLeave = (elem) => {
	if(!isSealed){
	    elem.currentTarget.children.edit.style.display = "none"
	    let elements = Array.from(elem.currentTarget.children).filter(child => child.id !== "edit");
	    for (let element of elements) {
		// console.log(element)
		// element.style.paddingRight = "20px";
	    }
	}

    }

    const scrollToBottom = () => {
	diaryEntriesRef.current?.scrollIntoView({behavior: "smooth"})
    }

    /**
     * Publish-Function -> exposes the Priv-Key of the Diary.
     * After Exposure the diary cannot be edited anymore
     */
    const publishDiary = () => {

	console.log("Publish Diary initalization...")

	diaryContract.publish(diary.owner.address, diary.owner.privateKey).then((tx) => {

	    console.log("Publishing personal Diary")

	    tx.wait().then(result => {
		console.log("Result", result)
		if(result.status === 1){
		    diaryContract.myDiary(diary.owner.address).then(diary => {
			console.log(diary)
			if(diary.isSealed) setIsSealed(diary.isSealed)
		    })
		}
		toast.success("You successfully published your Diary("+diary.owner.address+")")
	    })

	}).catch((error) => {
	    // console.table(error)
	    toast.error(error.reason, {autoClose: 3000, transition: Flip})
	})
    }

    //Listening to Publish-Events
    useEffect(() => {

	if(unlocked){

	    (async() => {
		console.log(diaryContract)
		// let myDiaryStats = await diaryContract.myDiary(diary.owner.address);
		diaryContract.myDiary(diary.owner.address).then((myDiaryStats) => {
		    console.log(myDiaryStats)
		    setIsSealed(myDiaryStats.isSealed)
		}).catch((error) => {
		    console.log(error)
		    toast.error(error)
		})
	    })();
	}

    }, [unlocked === true])

    useEffect(() => {
	if(unlocked){
	    fetchDiary();
	}
    }, [unlocked])

    useEffect(() => {
	if(unlocked){
	    fetchDiary();
	}
    }, [chainId])

    useEffect(() => {
	scrollToBottom();
    }, [text])

    console.log("Is this diary sealed?", isSealed)

    return (
	<main>
	    <div className={classNames(styles.book, (diary.isUnlocked() === true) ? styles.open : "")} onClick={handleOpenBook}>
		<div className={styles.bookCover}>
		    <div className={styles.frontCover}>
			<h1>{Diary.strings.DIARY_TITLE}</h1>
			<div className={styles.separator}/>
			<h2>by Myself</h2>

			<div style={{position: "absolute", bottom: "20px", left: "20px", opacity: 0.7}}>
			    <Logo logoType={1} style={{paddingLeft: 12, paddingRight: 12, backgroundColor: "transparent"}}/>
			</div>
		    </div>

		    <UnlockModalComponent
			showModal={showModal}
			setShowModal={setShowModal}
		    />

		    {
			(diary.isUnlocked())
			    ?
			    <div
				className={styles.backCover}
				style={{
				    opacity: (diary.isUnlocked() === true) ? 1 : 0,
				    width: (diary.isUnlocked() === true) ? "100%" : "0px",
				    overflow: "hidden",
				}}
			    >
				<div>
				    <DiaryOptionPanel
					closeBook={handleCloseBook}
					content={decryptedDiary}
					newContent={text}
					publishDiary={publishDiary}
					isSealed={isSealed}
				    />
				</div>
			    </div>
			    :
			    <></>
		    }
		</div>

		<div className={classNames(styles.bookContentStyle, styles.paperSheet)}>
		    <div className={classNames(styles.bookContent, styles.paper)}>
			<DiaryEntrySection className={classNames(styles.paperContent)} ref={diaryEntriesRef}>
			    {
				decryptedDiary.length === 0 ?
				    <p style={{textAlign: "center", color: "lightgrey"}}>Empty history</p>
				    :
				    decryptedDiary.map(([msg, timestamp], key) => {
					// return <div key={key}>{parse(msg)}</div>
					    return (
						<div key={key} id={key} className={classNames(styles.bookEntry)}
						     onMouseEnter={entryHover} onMouseLeave={entryHoverLeave}>
						    <div id={"edit"} style={{
							display: "none",
							justifyContent: "center",
							position: "absolute",
							left: "-50px",
							paddingRight: "20px",
							zIndex: "100"
						    }}>

							{
							    <Button variant={"contained"} onClick={() => {
								setEditMode({text: msg, index: key})
								setText(msg)
							    }}>Edit</Button>
							}
						    </div>
						    {
							(editMode !== null && key === editMode.index) ? "" : parse(msg)
							// parse(msg)
						    }
						    <hr/>

						</div>
					    )

				    })
			    }
			    <div ref={diaryEntriesRef}>
				{parse(text)}
			    </div>
			</DiaryEntrySection>
		    </div>

		    {
			(!isSealed)
			    ?
			    <EditorStyling>
				<CKEditor
				    editor={ClassicEditor}
				    data={text}
				    onChange={(event, editor) => {
					const data = editor.getData()
					// calcEstimatedGasCost(data)
					setText(data)
					// console.log(data)
					// encryptMsg(data)

					// decryptMsg(encryptedDiary)
				    }}
				/>
				<Button
				    className={"sendBtn"}
				    onClick={() => {
					if (editMode !== null) {
					    sendMsg(text, true, true)
					    setEditMode(null)
					} else {
					    sendMsg(text)
					}
				    }}
				>
				    {sendIcon}
				</Button>
			    </EditorStyling>
			    :
			    <></>

		    }


		</div>
	    </div>
	    <ExitDiv onClick={() => props.back()}/>
	</main>
    )

}
MyDiary.propTypes = {
    address: PropTypes.string,
    abi: PropTypes.array,
    back: PropTypes.func
}



/**
 * Modal for unlocking Diary
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function UnlockModalComponent(props) {

    const theme = useTheme();
    const {diary, web3, unlocked} = useDiary();

    const [lockIcon, setLockIcon] = useState(<LockIcon style={{fontSize: "6em", color: theme.global.base}}/>)
    const [buttonIcon, setButtonIcon] = useState(<LockOpenIcon/>)

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [passwordMatch, setPasswordMatch] = useState(null);
    const [errorText, setErrorText] = useState("");

    const [unlockedAnimation, setUnlockedAnimation] = useState(false);		//Change this to diary.isUnlocked
    const unlock = () => {
	setButtonIcon(<CircularProgress color="inherit" size={"2.0em"}/>)
	setTimeout(() => {
	    diary.unlockDiary(web3.utils.keccak256(password)).then((result) => {
		if(result){
		    setLockIcon(<LockOpenIcon style={{fontSize: "6em", color: theme.global.base}}/>)
		    setUnlockedAnimation(true)
		    clear()
		}else{
		    //Not successfull
		}
	    }).catch((error) => {
		console.error(error)
		console.table(error)
		setPasswordMatch(false)
		setErrorText(error.message)
		setButtonIcon(<LockOpenIcon/>)
	    })
	}, 1000);
    }

    const unlockWithConfirm = () => {
	if(password === passwordConfirm){
	    unlock();
	}else{
	    setPasswordMatch(false)
	    setErrorText("Passwords do not match")
	    setTimeout(()=>{
		setPasswordMatch(null)
		setErrorText("")
	    }, 3000)
	}

    }

    const clear = () => {
	setPassword("")
	setPasswordConfirm("")
	setErrorText("")
	setPasswordMatch(null);
    }

    useEffect(() => {
	if(unlocked === true){
	    props.setShowModal(false);
	    //RESET
	    setLockIcon(<LockIcon style={{fontSize: "6em", color: theme.global.base}}/>)
	    setButtonIcon(<LockOpenIcon/>)
	    setPassword("")
	    setPasswordConfirm("")
	    setErrorText("")
	    setPasswordMatch(null);
	    setUnlockedAnimation(false)
	}
    }, [unlocked])

    return (
	<UnlockModal style={{display: (props.showModal) ? "grid" : "none"}}>

	    <div id={"content"}>
		<div id={"closeBtn"} onClick={() => props.setShowModal(false)} ><CloseIcon/></div>

		<div>
		    {lockIcon}
		    {
			(unlockedAnimation === true) ? <div className={styles.firework}></div> : <></>
		    }

		</div>

		{
		    (diary.hasIdentity())
			?
			<>
			    <div style={{color: "black"}}>
				<p>{ Diary.strings.DIARY_LOCKED }</p>
				<p>{ Diary.strings.LOCK_IDENTITY_EXIST} </p>
			    </div>
			    <div style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
				<TextField
				    inputRef={(input) => input && input.focus()}
				    required
				    id="outlined-password-input"
				    label="Password"
				    type="password"
				    autoComplete="current-password"
				    value={password}
				    onChange={(elem) => {
					setPassword(elem.target.value);
				    }}
				    onKeyDown={(e) => e.key === "Enter" ? unlock() : ""}
				    error={(passwordMatch === false)}
				    helperText={ (passwordMatch === false) ? errorText : null}
				/>
				{/*<Button variant={"contained"} onClick={unlock}>{buttonIcon}</Button>*/}
			    </div>
			    <Button variant={"contained"} onClick={unlock}>{buttonIcon}</Button>
			</>

			:

			<>
			    <div style={{color: "black"}}>
				<p>{ Diary.strings.LOCK_IDENTITY_NOT_EXIST }</p>
			    </div>
			    <div style={{display: "flex", flexDirection: "column", gap: "12px", justifyContent: "center", alignContent: "center"}}>
				<TextField
				    required
				    id="outlined-password-input"
				    label="Password"
				    type="password"
				    autoComplete="current-password"
				    value={password}
				    onChange={(elem) => {
					setPassword(elem.target.value);
				    }}
				/>
				<TextField
				    required
				    error={(passwordMatch === false)}
				    id="outlined-password-input"
				    label="Confirm password"
				    type="password"
				    autoComplete="current-password"
				    value={passwordConfirm}
				    onChange={(elem) => {
					setPasswordConfirm(elem.target.value);
				    }}
				    helperText={ (passwordMatch === false) ? errorText : null}
				/>
			    </div>
			    <Button variant={"contained"} onClick={unlockWithConfirm}>{buttonIcon}</Button>
			</>

		}


	    </div>
	</UnlockModal>
    )
}
UnlockModalComponent.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    closeModal: PropTypes.func
}

/**
 * View for all Diary-releated Informations on the Left-Side
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function DiaryOptionPanel(props){

    return(
	<OptionPanel>
	    <div>
		<Button
		    variant={"outlined"}
		    endIcon={<LogoutIcon/>}
		    onClick={props.closeBook}
		>Close Diary</Button>
	    </div>
	    <br/>
	    <h2>Options</h2>

	    <DiaryInfoPanel/>

	    <TableOfContents content={props.content} newContent={props.newContent}/>

	    <ReleaseDiv>
		{
		    (!props.isSealed)
		    ?
			<Button
			    variant={"contained"}
			    endIcon={<PublishIcon/>}
			    onClick={props.publishDiary}
			>
			    Release Diary
			</Button>
		    :
			<div>
			    <span>Diary is sealed</span>
			    <Seal width={140}/>
			</div>
		}

	    </ReleaseDiv>

	</OptionPanel>
    )

}
DiaryOptionPanel.propTypes = {
    closeBook: PropTypes.func,
    content: PropTypes.any,
    newContent: PropTypes.any,
    publishDiary: PropTypes.func,
    isSealed: PropTypes.bool
}

/**
 * Info-Panel on left Side of the Diary.
 * Respectively the Infos for the Secured Book
 */
export function DiaryInfoPanel(props) {

    const {diary} = useDiary();

    const [showQR, setShowQR] = useState(false);


    const copyToClipboard = (text) => {
	//TODO: navigator.clipboard not working for mobile devices
	navigator.clipboard.writeText(text).then((result) => {
	    toast.info("Copied to Clipboard", {autoClose: 700, transition: Flip})
	})
    }

    const reduceKeyString = (keyString) => {
	let slicedKeyString = keyString.substring(0, 10) + "..." + keyString.substring(keyString.length - 8, keyString.length)
	return [slicedKeyString, keyString.length]
    }

    let pub = reduceKeyString(diary.owner.address);
    let priv = reduceKeyString(diary.owner.privateKey);

    return (
	<div style={{paddingBottom: "20px"}}>
	    <hr/>
	    <div style={{
		display: "grid",
		gridTemplateColumns: "auto auto",
		justifyItems: "stretch",
		fontSize: "0.9em",
		fontFamily: "consolas",
		padding: "10px",
		columnGap: "10px"
	    }}>
		<label style={{justifySelf: "left"}}>Diary:</label>
		<label>{pub[0] + "(" + pub[1] + ")"}</label>
		<label style={{justifySelf: "left"}}>Key: </label>
		<label>{priv[0] + "(" + priv[1] + ")"}</label>
		{/*<label>Priv: </label>*/}
		{/*<label style={{fontSize: "0.8em", lineBreak: "anywhere"}}>{props.priv}</label>*/}
	    </div>
	    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
		<button onClick={() => setShowQR(!showQR)}>Show QR</button>
		{
		    showQR ?
			<QRCode
			    title="roomKey"
			    value={"diary:" + props.priv}
			    bgColor="#FFFFFF"
			    // fgcolor="foreground-color"
			    // level="level"
			    size={128}
			    style={{}}
			/>
			:
			<></>
		}
	    </div>

	    <hr/>

	    <div style={{textAlign: "center"}}>
		<Button endIcon={<ContentCopyIcon/>} onClick={() => {
		    copyToClipboard(props.priv)
		}}>Copy Code</Button>
	    </div>

	    <hr/>

	    {/*<div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>*/}
	    {/*<Button onClick={() => {}} style={{minWidth: "40px"}}> <NavigateBeforeIcon/> </Button>*/}
	    {/*<label>Chapter: <span style={{fontWeight: 'bold'}}>#Nr</span></label>*/}
	    {/*<Button onClick={() => {}} style={{minWidth: "40px"}}> <NavigateNextIcon/> </Button>*/}
	    {/*</div>*/}
	</div>
    )

}
DiaryInfoPanel.propTypes = {
    pub: PropTypes.string,
    priv: PropTypes.string
}

/**
 * Table of Contents that index the Entries of the Book after decryption
 * @param props
 * @constructor
 */
const TableOfContentStyle = styled.div`
    
    display: grid;
    justify-content: center;
    justify-items: start;
    gap: 12px;
  
    padding-top: 10px;
  
    //display: list-item;
    font-size: 0.9em;
  
    h1{
    	//font-size: 1.0rem;  
    } 
    h2 {
    	
    }
    h3{
    	margin-left: 3.0rem;
      	//font-size: 1.0em; 
    }
    h4{
    	margin-left: 6.0rem;
    	//font-size: 0.0em;
    }
    
`;
export function TableOfContents(props){

    const TAGS = {
	H1: 'h1', H2: 'h2', H3: 'h3', P: 'p'
    };

    const Tab1 = `&nbsp;`;
    const Tab2 = `&ensp;`;
    const Tab3 = `&emsp;`;

    const headings = [];

    const analyze = (content, newContent = "") => {

	let domParser = new DOMParser();

	let document = domParser.parseFromString(content, "text/html");
	let extractedHeader = document.querySelectorAll("h2, h3, h4, h5, h6");
	for (let heading of extractedHeader) {
	    // if(heading.localName === TAGS.H2) {
		let node = createElement(heading.localName, {}, heading.innerHTML);
		headings.push(node);
	    // }
	    // if(heading.localName === TAGS.H3) {
		// let node = createElement(heading.localName, {}, heading.innerHTML);
		// headings.push(node);
	    // }
	    //
	    // if(heading.localName === TAGS.H3)
		// headings.push(heading.outerHTML);

	}

	if(newContent.length > 0)
	    analyze(newContent)

    }

    analyze(props.content, props.newContent)

    console.log("Headings", headings)

    if(headings.length === 0){
	let emptyNode = createElement("p", {className: styles.emptyHeading}, "Create a heading to fill Table of Content");
	headings.push(emptyNode)
    }

    return(
	<>
	    <h2>Table of Content</h2>
	    <TableOfContentStyle>
		{headings}
	    </TableOfContentStyle>
	</>
    )
}
TableOfContents.propTypes = {
    content: PropTypes.array,
    newContent: PropTypes.any
}
export default MyDiary;