import styled from "styled-components";

export const ExitDiv = styled.div`
    
    position: absolute;
    content: url("/images/door_closed_bold.svg");
    height: 60px;
    width: 60px;
    
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    //filter: invert(49%) sepia(53%) saturate(2305%) hue-rotate(166deg) brightness(107%) contrast(102%);
  
    &:hover{
	content: url("/images/door_open_bold.svg");
	bottom: 8px;
	//top: calc(100% - 52px);
	transform: scaleY(1.03);
    }
`;

export const LibraryDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  //grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
    gap: 3rem;
    margin: 50px 20%;
  
`;

export const DocumentDiv = styled.div`
    position: relative;
    //display: flex;
    //flex-direction: column;
    //justify-content: space-evenly;
    //align-items: center;
  
    border: 1px solid black;
    width: 100%;
    height: 160px;
    border-radius: 10px;
  
`;

export const ReadOverlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    cursor: pointer;
`;

export const DiaryInfoDiv = styled.div`
    display: grid;
    grid-template-rows: 45% 55%;
    height: 100%;
`;